import React, { useEffect } from "react";
import "./modal.css"

const Modal = ({ modalOpen, setModalOpen , children }) => {
  useEffect(() => {
    if (modalOpen) {
      
      document.documentElement.style.overflowY = 'hidden';
    } else {
     
      document.documentElement.style.overflowY = 'auto';
    }
 
    return () => {
      
      document.documentElement.style.overflowY = 'auto';
    };
  }, [modalOpen]);
  return (
    <>
  
        <div className="whole_area position-absolute">
          <div className="backdrop"></div>
          <div className="modal_container"> {children}</div>
        </div>

    </>
  );
};

export default Modal;
